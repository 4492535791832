import React from "react";
import Chart from "chart.js/auto";
import {
  Statistic,
  ReportHeading,
  Breakdown,
} from "../../helpers/uiComponents";
import {
  secondsToTime,
  camelToUpperCase,
  numberToLocal,
} from "../../helpers/uiFunctions";
import { chartColors } from "../../helpers/uiHelpers";
import {
  loadAnalyticsDailyMetricsGA4,
  loadAnalyticsQuickStatsGA4,
  loadDimensionGA4,
} from "../data/supermetricsService";

class GA4Analytics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quickStats: null,
      channelGrouping: null,
      browser: null,
      socialNetwork: null,
      sourceMedium: null,
      message: null,
    };
  }
  componentDidMount() {
    loadAnalyticsQuickStatsGA4(
      //load quickstats
      this.props.endpoints,
      this.props.dates,
      this.props.currentClient.clientCode,
      localStorage.getItem("accessToken")
    ).then((response) => {
      if (response.success) {
        var keys = response.result.data[0];
        var values = response.result.data[1];
        var data = {};
        keys.forEach((key, i) => (data[key] = values[i]));
        this.setState({ quickStats: data });
      } else {
        this.setState({ message: true });
      }
    });
    loadAnalyticsDailyMetricsGA4(
      //load daily metrics
      this.props.endpoints,
      this.props.dates,
      this.props.currentClient.clientCode,
      localStorage.getItem("accessToken")
    ).then((response) => {
      // console.log('graphData-', response);
      if (response.success) {
        // console.log('beforeShift-', response.result.data[0]);
        response.result.data.shift();
        // console.log('afterShift', response.result.data);
        setTimeout(() => {
          this.buildDailyChart(
            "Sessions",
            response.result.data,
            1,
            "sessions-graph"
          ); /*title, inputData, index, elemId*/
          this.buildDailyChart(
            "Users",
            response.result.data,
            2,
            "users-graph"
          ); /*title, inputData, index, elemId*/
          this.buildDailyChart(
            "New Users",
            response.result.data,
            3,
            "newusers-graph"
          ); /*title, inputData, index, elemId*/
          this.buildDailyChart(
            "Page Views",
            response.result.data,
            4,
            "pageviews-graph"
          ); /*title, inputData, index, elemId*/
        //   this.buildDailyChart(
        //     "Bounce Rate (%)",
        //     response.result.data,
        //     5,
        //     "bouncerate-graph"
        //   ); /*title, inputData, index, elemId*/
          this.buildDailyChart(
            "Average Session Length (seconds)",
            response.result.data,
            6,
            "sessionlength-graph"
          ); /*title, inputData, index, elemId*/
          this.buildDailyChart(
            "Conversions",
            response.result.data,
            7,
            "goalCompletionsAll-graph"
          ); /*title, inputData, index, elemId*/
          this.buildDailyChart(
            "Engagement Rate (%)",
            response.result.data,
            8,
            "engagementerate-graph"
          ); /*title, inputData, index, elemId*/
        }, 500);
        // this.setState({ metrics: response.result.data });
      }
    });
    loadDimensionGA4(
      //load channel grouping
      this.props.endpoints,
      this.props.dates,
      "sessionDefaultChannelGrouping",
      this.props.currentClient.clientCode,
      localStorage.getItem("accessToken")
    ).then((response) => {
      if (response.success) {
        response.result.data.shift();
        this.setState({ channelGrouping: response.result.data });
        setTimeout(() => {
          this.buildPieChart(
            response.result.data,
            "channelGrouping",
            "channelGrouping-graph"
          );
        }, 500);
      }
    });

    loadDimensionGA4(
      //load browsers
      this.props.endpoints,
      this.props.dates,
      "browser",
      this.props.currentClient.clientCode,
      localStorage.getItem("accessToken")
    ).then((response) => {
      // console.log(response);
      if (response.success) {
        response.result.data.shift();
        this.setState({ browser: response.result.data });
        setTimeout(() => {
          this.buildPieChart(response.result.data, "browser", "browser-graph");
        }, 500);
      }
    });
    loadDimensionGA4(
      //load social networks
      this.props.endpoints,
      this.props.dates,
      "sessionSource",
      this.props.currentClient.clientCode,
      localStorage.getItem("accessToken"),
      "sessionDefaultChannelGrouping =@ Social"
    ).then((response) => {
      if (response.success) {
        let data = response.result.data;
        data.shift();
        data = data.filter((key) => {
          return key[0] !== "(not set)";
        });
        this.setState({ socialNetwork: data });
        setTimeout(() => {
          this.buildPieChart(data, "socialNetwork", "socialNetwork-graph");
        }, 500);
      }
    });
    loadDimensionGA4(
      //load source medium
      this.props.endpoints,
      this.props.dates,
      "sessionSource",
      this.props.currentClient.clientCode,
      localStorage.getItem("accessToken")
    ).then((response) => {
      if (response.success) {
        response.result.data.shift();
        this.setState({ sourceMedium: response.result.data });
        setTimeout(() => {
          this.buildPieChart(
            response.result.data,
            "sourceMedium",
            "sourceMedium-graph"
          );
        }, 500);
      }
    });
  }

  buildDailyChart(title, inputData, index, elemId) {
    if (inputData) {
    //   console.log('Input Data--', inputData);
      var data = [];
      var options = [...new Set(inputData.map((x) => x[0]))];
      for (var i = inputData.length - 1; i >= 0; i--) {
        data.push(inputData[i][index]);
      }
      data.reverse(); //options.reverse();
      var ctx = document.getElementById(elemId);
      new Chart(ctx, {
        type: "line",
        data: {
          labels: options,
          datasets: [
            {
              data: data,
              backgroundColor: "rgba(72, 152, 187, 0.3)",
              borderColor: "rgba(72, 152, 187, 1)",
              pointBackgroundColor: "rgba(190, 209, 47, 1)",
              pointRadius: 5,
              fill: "start",
            },
          ],
        },
        options: {
          plugins: {
            aspectRatio: 1,
            maintainAspectRatio: false,
            legend: { display: false },
            title: {
              display: true,
              text: title,
              fontSize: 16,
            },
          },
          layout: {
            padding: { left: 50, right: 50, top: 25, bottom: 25 },
          },
          scales: {
            xAxes: [{ stacked: true }],
            yAxes: [{ stacked: true }],
          },
          animations: {
            linear: {
              duration: 1000,
              easing: "linear",
              from: 1,
              to: 0,
              loop: true,
            },
          },
        },
      });
    }
  }

  buildPieChart(inputData, dimension, elemId) {
    var data = [];
    var options = [];
    for (let i = 0; i < inputData.length; i++) {
      options.push(inputData[i][0]);
      data.push(inputData[i][1]);
    }
    // console.log(data);
    var ctx = document.getElementById(elemId);
    new Chart(ctx, {
      type: "doughnut",
      data: {
        labels: options,
        datasets: [
          {
            data: data,
            backgroundColor: chartColors,
          },
        ],
      },
      options: {
        plugins: {
          maintainAspectRatio: false,
          aspectRatio: 1,
          legend: { display: false, position: "bottom" },
          title: {
            display: true,
            text: "Top Session " + camelToUpperCase(dimension) + "s",
            fontSize: 16,
          },
        },
        layout: {
          padding: { left: 50, right: 50, top: 25, bottom: 25 },
        },
        animations: {
          linear: {
            duration: 1000,
            easing: "linear",
            from: 1,
            to: 0,
            loop: true,
          },
        },
      },
    });
  }
  toggleTab(i, metric) {
    var menuItems = document.querySelectorAll(
      "#metrics-ga4analytics .tab-menu .tab"
    );
    for (var x = menuItems.length - 1; x >= 0; x--) {
      menuItems[x].classList.remove("active");
      menuItems[i - 1].classList.add("active");
    }
    var graph = document.querySelector(".stats-graph." + metric);
    var graphs = document.querySelectorAll(".stats-graph");
    for (var y = graphs.length - 1; y >= 0; y--) {
      graphs[y].classList.remove("active");
    }
    graph.classList.add("active");
  }
  toggle(metric) {
    var button = document.querySelector(".breakdown button." + metric);
    var buttons = document.querySelectorAll(".breakdown button");
    var graph = document.querySelector(".graph-container." + metric);
    var graphs = document.querySelectorAll(".graph.analytics .graph-container");
    for (var i = buttons.length - 1; i >= 0; i--) {
      buttons[i].classList.remove("active");
      graphs[i].classList.remove("active");
    }
    button.classList.add("active");
    graph.classList.add("active");
  }
  render() {
    const {
      message,
      hideHistorical,
      quickStats,
      channelGrouping,
      browser,
      socialNetwork,
      sourceMedium,
    } = this.state;

    return (
      this.props.currentClient && (
        <div id="metrics-ga4analytics" className="report metrics-ga4analytics">
          <ReportHeading
            title="Google Analytics 4 Statistics"
            name={this.props.currentClient.name}
            dates={this.props.dates}
            hideHistorical={hideHistorical}
            onClick={this.props.onClick}
          />
          {message && (
            <React.Fragment>
              <h3>
                {" "}
                We were unable to fetch your Google Analytics 4 Statistics.{" "}
              </h3>
              <p>
                {" "}
                There may have been an error connecting to Google Analytics 4,
                or Google Analytics 4 is not configured for your Portal.{" "}
              </p>
              <p> Please contact your Account Manager for assistance. </p>
            </React.Fragment>
          )}
          {!message && (
            <React.Fragment>
              <section className="stats row">
                <Statistic
                  label="Sessions"
                  value={
                    quickStats
                      ? numberToLocal(quickStats["Sessions"])
                      : "calculating.."
                  }
                  status={
                    quickStats && quickStats["Change % Sessions"] < 0
                      ? "down"
                      : "up"
                  }
                  percent={quickStats ? quickStats["Change % Sessions"] : 0}
                  hideHistorical={hideHistorical}
                />
                <Statistic
                  label="Unique Users"
                  value={
                    quickStats
                      ? numberToLocal(quickStats["Total users"])
                      : "calculating.."
                  }
                  status={
                    quickStats && quickStats["Change % Total users"] < 0
                      ? "down"
                      : "up"
                  }
                  percent={quickStats ? quickStats["Change % Total users"] : 0}
                  hideHistorical={hideHistorical}
                />
                <Statistic
                  label="Page Views"
                  value={
                    quickStats
                      ? numberToLocal(quickStats["Views"])
                      : "calculating.."
                  }
                  status={
                    quickStats && quickStats["Change % Views"] < 0
                      ? "down"
                      : "up"
                  }
                  percent={quickStats ? quickStats["Change % Views"] : 0}
                  hideHistorical={hideHistorical}
                />
                <Statistic
                  label="Engagement Rate"
                  value={
                    quickStats
                      ? numberToLocal(quickStats["Engagement rate"])
                      : "calculating.."
                  }
                  status={
                    quickStats && quickStats["Change % Engagement rate"] < 0
                      ? "down"
                      : "up"
                  }
                  percent={
                    quickStats ? quickStats["Change % Engagement rate"] : 0
                  }
                  hideHistorical={hideHistorical}
                />
                {/* <Statistic label="Bounce Rate"
                                    value={
                                        (quickStats) ? (numberToLocal(quickStats['Bounce rate'])) : ("calculating..")}
                                    status={
                                        (quickStats && (quickStats['Change % Bounce rate'] < 0)) ? ("down") : ("up")}
                                    percent={
                                        (quickStats) ? (quickStats['Change % Bounce rate']) : (0)}
                                    hideHistorical={hideHistorical}
                                /> */}
                {/* <Statistic label="Average Session Time"
                                    value={
                                        (quickStats) ? (secondsToTime(quickStats['Average session duration'])) : ("calculating..")}
                                    status={
                                        (quickStats && (quickStats['Change % Average session duration'] < 0)) ? ("down") : ("up")}
                                    percent={
                                        (quickStats) ? (quickStats['Change % Average session duration']) : (0)}
                                    hideHistorical={hideHistorical}
                                /> */}
                <Statistic
                  label="Avg. Engagement Time"
                  value={
                    quickStats
                      ? secondsToTime(
                          quickStats["Average session length (sec)"]
                        )
                      : "calculating.."
                  }
                  status={
                    quickStats &&
                    quickStats["Change % Average session length (sec)"] < 0
                      ? "down"
                      : "up"
                  }
                  percent={
                    quickStats
                      ? quickStats["Change % Average session length (sec)"]
                      : 0
                  }
                  hideHistorical={hideHistorical}
                />
              </section>

              <section className="stats-graph">
                <div className="graph wide analytics">
                  <div className="graph-container sessions active">
                    <canvas className="chart" id="sessions-graph" />
                  </div>{" "}
                  <div className="graph-container users">
                    <canvas className="chart" id="users-graph" />
                  </div>{" "}
                  <div className="graph-container newusers">
                    <canvas className="chart" id="newusers-graph" />
                  </div>{" "}
                  <div className="graph-container pageviews">
                    <canvas className="chart" id="pageviews-graph" />
                  </div>{" "}
                  {/* <div className="graph-container bouncerate">
                    <canvas className="chart" id="bouncerate-graph" />
                  </div>{" "} */}
                  <div className="graph-container sessionlength">
                    <canvas className="chart" id="sessionlength-graph" />
                  </div>{" "}
                  <div className="graph-container goalCompletionsAll">
                    <canvas className="chart" id="goalCompletionsAll-graph" />
                  </div>{" "}
                  <div className="graph-container engagementerate">
                    <canvas className="chart" id="engagementerate-graph" />
                  </div>{" "}
                </div>
                <div className="breakdown">
                  <p className="title"> Metrics </p>{" "}
                  <div className="graph-controls">
                    <button
                      className="wide white sessions active"
                      onClick={() => this.toggle("sessions")}
                    >
                      {" "}
                      Sessions{" "}
                    </button>
                    <button
                      className="wide white users"
                      onClick={() => this.toggle("users")}
                    >
                      {" "}
                      Users{" "}
                    </button>
                    <button
                      className="wide white newusers"
                      onClick={() => this.toggle("newusers")}
                    >
                      {" "}
                      New Users{" "}
                    </button>{" "}
                    <button
                      className="wide white pageviews"
                      onClick={() => this.toggle("pageviews")}
                    >
                      {" "}
                      Page Views{" "}
                    </button>{" "}
                    {/* <button
                      className="wide white bouncerate"
                      onClick={() => this.toggle("bouncerate")}
                    >
                      {" "}
                      Bounce Rate{" "}
                    </button>{" "} */}
                    <button
                      className="wide white sessionlength"
                      onClick={() => this.toggle("sessionlength")}
                    >
                      {" "}
                      Average Session Time{" "}
                    </button>{" "}
                    <button
                      className="wide white goalCompletionsAll"
                      onClick={() => this.toggle("goalCompletionsAll")}
                    >
                      {" "}
                      Conversions{" "}
                    </button>{" "}
                    <button
                      className="wide white engagementerate"
                      onClick={() => this.toggle("engagementerate")}
                    >
                      {" "}
                      Engagement Rate{" "}
                    </button>
                  </div>
                </div>
              </section>

              <div className="tab-menu">
                <div
                  onClick={() => this.toggleTab(1, "channelGrouping")}
                  className="tab active"
                >
                  {" "}
                  Channels{" "}
                </div>{" "}
                <div
                  onClick={() => this.toggleTab(2, "browser")}
                  className="tab"
                >
                  {" "}
                  Browsers{" "}
                </div>{" "}
                <div
                  onClick={() => this.toggleTab(3, "socialNetwork")}
                  className="tab"
                >
                  {" "}
                  Social Networks{" "}
                </div>{" "}
                <div
                  onClick={() => this.toggleTab(4, "sourceMedium")}
                  className="tab"
                >
                  {" "}
                  Referral Sources{" "}
                </div>{" "}
              </div>
              <section className="stats-graph dimension channelGrouping active">
                <div className="graph wide">
                  <div className="graph-container">
                    <canvas className="chart" id="channelGrouping-graph" />
                  </div>
                </div>
                {channelGrouping && (
                  <Breakdown
                    data={channelGrouping}
                    title={"Top Channels"}
                    format={"wide"}
                    dataIndex={1}
                  />
                )}
              </section>
              <section className="stats-graph dimension browser">
                <div className="graph wide">
                  <div className="graph-container">
                    <canvas className="chart" id="browser-graph" />
                  </div>
                </div>
                {browser && (
                  <Breakdown
                    data={browser}
                    title={"Top Browers"}
                    format={"wide"}
                    dataIndex={1}
                  />
                )}
              </section>
              <section className="stats-graph dimension socialNetwork">
                <div className="graph wide">
                  <div className="graph-container">
                    <canvas className="chart" id="socialNetwork-graph" />
                  </div>
                </div>
                {socialNetwork && (
                  <Breakdown
                    data={socialNetwork}
                    title={"Top Social Networks"}
                    format={"wide"}
                    dataIndex={1}
                  />
                )}
              </section>
              <section className="stats-graph dimension sourceMedium">
                <div className="graph wide">
                  <div className="graph-container">
                    <canvas className="chart" id="sourceMedium-graph" />
                  </div>{" "}
                </div>{" "}
                {sourceMedium && (
                  <Breakdown
                    data={sourceMedium}
                    title={"Top Referral Sources"}
                    format={"wide"}
                    dataIndex={1}
                  />
                )}
              </section>
            </React.Fragment>
          )}
        </div>
      )
    );
  }
}

export default GA4Analytics;
