import React from 'react';
import Chart from 'chart.js/auto';

import { getPercentChange, countLeads, formatDateTimeLocal, formatDateDisplay, getTopResult, numberToLocal, numberToPrice } from '../../helpers/uiFunctions';
import { Statistic, ReportHeading } from '../../helpers/uiComponents';
import LeadWindow from './leadComponents/leadWindow';
import { InputRow } from '../../helpers/inputRow';
import { SearchForm } from '../../helpers/search';

class LeadTracking extends React.Component {
	
	constructor(props) {
        super(props);
        this.state = {
			allLeads: [],
			leads: [],
			oldLeads: [],
			count: 0,
			oldCount: 0,
			totals: [],
			sortVal: 0,
			currentPage: 1,
			leadsPerPage: 25,
			tabName: "All",
			affiliateCode: null,
			retailerList: null,
			name: null,
			loading: true,
			editId: null,
			editDetails: false,
        };
        this.changePage = this.changePage.bind(this);
        this.changeLeadCount = this.changeLeadCount.bind(this);
        this.loadRetailer = this.loadRetailer.bind(this);
		this.close = this.close.bind(this);
		this.save = this.save.bind(this);
		this.toggleTestLeads = this.toggleTestLeads.bind(this);
    }

	componentDidMount() {
		try {
			if (this.props.leads) {
				this.handleData();
			}
		} catch (error) {
			console.log(error);
		}
	}
	componentDidUpdate(prevProps) {
		try {
			if (this.props.leads) {
				if (this.props.leads.daily && (this.props.leads !== prevProps.leads)  && !this.props.leads.message) {
					this.handleData();
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	handleData() {
		this.setState({
			name: this.props.currentClient.name,
			leads: this.props.leads.leads.filter(lead => { return typeof lead.conversionStatus === 'undefined' || lead.conversionStatus === null}),
			allLeads: this.props.leads.leads,
			oldLeads: this.props.oldLeads.leads,
			count: this.props.leads.count,
			oldCount: this.props.oldLeads.count,
			loading: false,
		})
		this.buildChart(this.props.leads.daily);
		this.getTotals(this.props.leads.leads);
	}

	getTotals(leads) {
		//console.log(calls);
		var arr = []
		arr["All"] = leads.length;
		arr["New"] = leads.filter((obj) => typeof obj.conversionStatus === 'undefined' || obj.conversionStatus === null).length;
		arr["Qualified"] = leads.filter((obj) => obj.conversionStatus === "Qualified").length;
		arr["Opportunity"] = leads.filter((obj) => obj.conversionStatus === "Opportunity").length;
		arr["Won"] = leads.filter((obj) => obj.conversionStatus === "Won").length;
		arr["Lost"] = leads.filter((obj) => obj.conversionStatus === "Lost").length;
		arr["Disqualified"] = leads.filter((obj) => obj.conversionStatus === "Disqualified").length;


		var leadValue = 0;
		var wonValue = 0;
		leads.forEach(function(lead) {
			leadValue = leadValue + lead.value;

			if (lead.conversionStatus === "Won") {
				wonValue = wonValue + lead.value;
			}
		});
		arr["leadValue"] = leadValue;
		arr["wonValue"] = wonValue;


		this.setState({
			totals: arr,
		});
		// console.log(arr);
	}

	buildChart(daily) {
		if (daily) {
			var data = [];
			var options = [];

			for (var i = daily.length - 1; i >= 0; i--) {
				data.push(daily[i].count);
				options.push(formatDateDisplay(daily[i].date));
			}
			data.reverse(); options.reverse();

			var ctx = document.getElementById('graph-lead-by-day');
			new Chart(ctx, {
				"type":"line",
				"data": {
					"labels":options,
					"datasets":[
						{
							data:data,
							backgroundColor: "rgba(72, 152, 187, 0.3)",
							borderColor: "rgba(72, 152, 187, 1)",
							pointBackgroundColor: "rgba(190, 209, 47, 1)",
							pointRadius: 5,
							fill: "start",
						}
					]
				},
				options: {
					plugins: {
						title: {
							display: true,
							text: "Leads By Day",
							fontSize: 16,
						},
						legend: {
							display: false,
						},
					},
					aspectRatio: 1,
					maintainAspectRatio: false,
					layout: {
						padding: {
							left: 50,
							right: 50,
							top: 25,
							bottom: 25
						}
					},
					scales: {
						xAxes: [{
							stacked: true,
						}],
						yAxes: [{
							stacked: true
						}]
					},
					animations: {
						linear: {
							duration: 1000,
							easing: 'linear',
							from: 1,
							to: 0,
							loop: true
						}
					},
				}
			});
		}
	}
	showDetails(id) {
		var items = document.querySelectorAll('#metrics-leads .leads-list .table-body li');
		
		for (var x = items.length - 1; x >= 0; x--) {
			items[x].classList.remove('active');
		}
		if(id === this.state.editId) {
			this.close();
		} else {
			this.setState({ editDetails: true, editId: id });
			document.getElementById("lead-"+id).classList.add('active');
			console.log("show firing")
		}
	}
	close() {
		console.log("close firing")
		this.setState({ editDetails: false, editId: null }, () => {
			console.log(this.state);
		});
	}
	save(id) {
		// get leads to update later with response data
		var newLeads = this.state.leads;
		var newAllLeads = this.state.allLeads;
		var leadIndex = newLeads.findIndex(lead => lead['id'] === id );
		var allLeadIndex = newAllLeads.findIndex(lead => lead['id'] === id );
		// get values from form
		var status = document.getElementById("status-"+id).value;
		var detail = document.getElementById("detail-"+id).value;
		var value = document.getElementById("value-"+id).value;
			if(!value) {
				value = 0;
			}
			value = parseFloat(value).toFixed(2);
		// set fetch variables
		var params = {
			headers: {"authorization": "bearer " + this.props.token},
			method: "POST",
		}
		var statusURL = this.props.endpoints.serviceCrm+"status/"+id+"/"+status +"/"+value
		if(detail) {
			statusURL = statusURL+"?detail="+detail
		}
		
		var valueURL = this.props.endpoints.serviceCrm+"value/"+id+"/"+value
		// fetch
		fetch(statusURL, params)
		.then(response => {
			if (response.ok) { return response.json(); } 
			else { throw new Error('Something went wrong setting the status...'); }
		})
		.then(data => {
			console.log(data);
			console.log(newLeads);
			console.log(newAllLeads);
			if (data.success) {
		        newAllLeads[allLeadIndex]['conversionStatus'] = data.result.conversionStatus;
		        newAllLeads[allLeadIndex]['conversionDetail'] = data.result.conversionDetail;
		        newAllLeads[allLeadIndex]['workflow'] = data.result.workflow;
				newLeads[leadIndex]['conversionStatus'] = data.result.conversionStatus;
		        newLeads[leadIndex]['conversionDetail'] = data.result.conversionDetail;
		        newLeads[leadIndex]['workflow'] = data.result.workflow;
			}
			// return fetch(valueURL, params);
		})
		.then(response => {
			if (response.ok) { return response.json(); } 
			else { throw new Error('Something went wrong setting the value...'); }
		})
		.then(data => {
			console.log(data);
			console.log(newLeads);
			console.log(newAllLeads);
			if (data.success) {
		        newAllLeads[allLeadIndex]['value'] = data.result.value;
				newLeads[leadIndex]['value'] = data.result.value;

		        this.setState({
					leads: newLeads,
					allLeads: newAllLeads,
				}, () => {
					this.getTotals(newAllLeads);
				}); 
			}
		})
		.catch(error => console.log(error));
	}

	sortBy(prop) {
		var list = this.state.leads;
		var sort = this.state.sortVal;
		if (sort === 0 || sort === 2) {
			list = list.sort((a, b) => (a[prop] > b[prop]) ? 1 : -1);
			sort = 1;
		} else {
			list = list.sort((a, b) => (a[prop] < b[prop]) ? 1 : -1);
			sort = 2;
		}		
		this.setState({
			leads: list,
			sortVal: sort,  // up or down
		});
	}

	toggleTabs(status, i) {
		var newLeads = this.state.allLeads;
		var menuItems = document.querySelectorAll('#metrics-leads .tab-menu .tab');
		
		for (var x = menuItems.length - 1; x >= 0; x--) {
			menuItems[x].classList.remove('active');
			menuItems[i-1].classList.add('active');
		}

		if (status === "All") {
			// silence is golden
		} else if (status === "New") {
			newLeads = newLeads.filter(lead => { return typeof lead.conversionStatus === 'undefined' || lead.conversionStatus === null});
		} else {
			newLeads = newLeads.filter(lead => { return lead.conversionStatus === status});
		}

		this.setState({
			leads: newLeads,
			tabName: status,
		});
	}
	toggleTestLeads(e) {
		e.target.classList.toggle('active');
		let showTest = localStorage.getItem('showTest');
		if (showTest === "false") {
			localStorage.setItem('showTest', "true");
			e.target.innerText = 'Remove Test Leads';
		} else {
			localStorage.setItem('showTest', "false");
			e.target.innerText = 'Include Test Leads';
		}
		var affiliateCode = this.props.affiliateCode;
		var clientCode = this.props.currentClient.clientCode;
		var changeClient = this.props.changeClient;
		changeClient(clientCode, affiliateCode);

		this.setState({loading: true});
	}

	changePage(e) {
		this.setState({
			currentPage: Number(e.target.id),
		});
	}
	changeLeadCount(e) {
		this.setState({
			leadsPerPage: e.target.options[e.target.selectedIndex].value,
			currentPage: 1,
		});
	}

	loadRetailer(e) {
		var affiliateCode = e.target.value;
		var clientCode = this.props.currentClient.clientCode;
		var changeClient = this.props.changeClient;

		changeClient(clientCode, affiliateCode);
	}
	clearRetailer() {
		var affiliateCode = null;
		var clientCode = this.props.currentClient.clientCode;
		var changeClient = this.props.changeClient;

		changeClient(clientCode, affiliateCode);
	}

	render() {
		const {leads, oldLeads, count, oldCount, totals, currentPage, leadsPerPage, editDetails, editId, name, loading } = this.state;
		const hideHistorical = this.props.hideHistorical;
		const roles = this.props.roles;
		const isRetailer = this.props.isRetailer;
		const retailerList = this.props.retailerList;

		var top1 = {}; var top2 = {};
		if (leads) {
			top1 = getTopResult(leads, "channel", 0);
			top2 = getTopResult(leads, "form", 0);
		}

		let currentLeads = leads;

		if (leadsPerPage !== "all") {
			let indexOfLastTodo = currentPage * leadsPerPage;
			let indexOfFirstTodo = indexOfLastTodo - leadsPerPage;
			currentLeads = leads.slice(indexOfFirstTodo, indexOfLastTodo);
		}

		const affCode = this.props.affiliateCode;
		if (this.props.affiliateCode) {
			document.querySelectorAll(".lead-retailer-list").forEach(function(select) {
  				select.value = affCode
			});
		}

		let showTest = localStorage.getItem('showTest');
		let toggleClass = "";
		if (showTest === "true") {
			toggleClass = "active"
		}

		let renderLeads = currentLeads.map((lead) => {
			//console.log(lead);
			return (
				<React.Fragment key={lead.id}>
					<li key={lead.id} id={"lead-"+lead.id} className={(lead["conversionStatus"] === null) ? ( "New" ) : ( lead["conversionStatus"] )} onClick={() => this.showDetails(lead.id)}>
						<div>{formatDateTimeLocal(lead.createdTime)[0]}<br/>{formatDateTimeLocal(lead.createdTime)[1]}</div>
						<div>{lead.name}</div>
						<div>{lead.email}</div>
						<div>{lead.phone}</div>
						<div>
							<div>
								{(lead.conversionStatus === null) ? (
									<span>New</span>
								) : (
									<span>{lead.conversionStatus}</span>
								)}
							</div>
						</div>
						<div>{lead.channel}</div>
						<div className="no-overflow">{lead.form}</div>
						<div>{(lead.location) && (lead.location)}</div>
						<div>{(lead.productInterest) && (lead.productInterest)}</div>
						<div className="no-overflow">{
							(lead.comments) && (
								<p className="comments">
									<i className="fas fa-comments"></i> {lead.comments}
								</p>
							)
						}</div>
						<div>
							<div className='bold-text-last'>
								{numberToPrice(lead.value)}
							</div>
						</div>
					</li>
					{(editDetails === true && editId === lead.id) && (
						<LeadWindow lead={lead} save={this.save} close={this.close} endpoints={this.props.endpoints} token={this.props.token} />
					)}
				</React.Fragment>
			);
		});

		const pageNumbers = [];
		for (let i = 1; i <= Math.ceil(leads.length / leadsPerPage); i++) {
			pageNumbers.push(i);
		}

		const renderPageNumbers = pageNumbers.map(number => {
			return (
				(number === this.state.currentPage) ? (
					<li key={number} id={number} onClick={this.changePage} className="active" >
						{number}
					</li>
				) : (
					<li key={number} id={number} onClick={this.changePage} >
						{number}
					</li>
				)
			);
		});

		return(
			(this.props.currentClient) && (

				<div id="metrics-leads" className="report metrics-leads active">
					<ReportHeading
						title="Web Leads"
						name={name}
						dates={this.props.dates}
						hideHistorical={hideHistorical}
					/>

					<div className='right_btn_wrapper'>
					<button className={"white include-testLeads "+toggleClass} onClick={this.toggleTestLeads}>
						{toggleClass === "active" ? "Remove Test Leads" : "Include Test Leads"}
					</button>
					<button className="white download-report" onClick={() => this.props.downloadReport("leads")}>Download Leads Report</button>
					</div>
					{( (isRetailer === false) && (retailerList) && (roles.includes("portalAdm") || roles.includes("portalVlctyMngr")) ) && (
        				<div className="retailer-selector">
	            			Retailer:
	            			<span className="clear-client"><i className="far fa-window-close" onClick={() => this.clearRetailer()}></i></span>
	            			<InputRow type="select" className="lead-retailer-list" property="retailerList" options={retailerList} onChange={this.loadRetailer} />
	            		</div>
            		)}

					<React.Fragment>
						<section className="stats row">
							<Statistic
								label={["total", <span>Leads</span>]}
								value={numberToLocal(count)}
								status={getPercentChange(count, oldCount)[1]}
								percent={getPercentChange(count, oldCount)[0]}
								hideHistorical={hideHistorical}
							/>
							{(top1 && oldLeads) && (
								<Statistic
									label={["top channel",<span>{top1.key}</span>]}
									value={numberToLocal(top1.val)}
									status={ getPercentChange( (top1.val), (countLeads(oldLeads, top1.key, "channel")) )[1]}
									percent={getPercentChange( (top1.val), (countLeads(oldLeads, top1.key, "channel")) )[0]}
									hideHistorical={hideHistorical}
								/>
							)}
							{(top2 && oldLeads) && (
								<Statistic
									label={["top form",<span>{top2.key}</span>]}
									value={numberToLocal(top2.val)}
									status={ getPercentChange( (top2.val), (countLeads(oldLeads, top2.key, "form")) )[1]}
									percent={getPercentChange( (top2.val), (countLeads(oldLeads, top2.key, "form")) )[0]}
									hideHistorical={hideHistorical}
								/>
							)}
							<Statistic
								label={["total", <span>lead value</span>]}
								value={numberToPrice(totals["leadValue"])}
								
								hideHistorical={"true"}
							/>
							<Statistic
								label={["total", <span>lead value won</span>]}
								value={numberToPrice(totals["wonValue"])}
								
								hideHistorical={"true"}
							/>
						</section>
						{(leads) && (
							<section className="stats-graph">
								<div className="graph wide">
									<div className="graph-container">
										<canvas className="chart" id="graph-lead-by-day" />
									</div>
								</div>
							</section>
						)}

						{(loading === true) && (
	            			<div className="report-loading">
	            				<p><i className="fas fa-circle-notch"></i></p>
	            			</div>
	            		)}

	            		<div className="tab-menu">
		            		<div onClick={() => this.toggleTabs("New", 1)} className="tab New active">New ({totals["New"]})</div>
		            		<div onClick={() => this.toggleTabs("Qualified", 2)} className="tab Qualified">Qualified ({totals["Qualified"]})</div>
		            		<div onClick={() => this.toggleTabs("Opportunity", 3)} className="tab Opportunity">Opportunity ({totals["Opportunity"]})</div>
		            		<div onClick={() => this.toggleTabs("Won", 4)} className="tab Won">Won ({totals["Won"]})</div>
		            		<div onClick={() => this.toggleTabs("Lost", 5)} className="tab Lost">Lost ({totals["Lost"]})</div>
		            		<div onClick={() => this.toggleTabs("Disqualified", 6)} className="tab Disqualified">Disqualified ({totals["Disqualified"]})</div>
							<div onClick={() => this.toggleTabs("All", 7)} className="tab All">All Leads ({totals["All"]})</div>
	            		</div>
						<section className="leads-list">
								{/* <div className="pagination">
									<ul className="page-numbers">
										{renderPageNumbers}
									</ul>
									<InputRow 
										label="Results Per Page" type="select" property="leadsPerPage" id="resultsPerPage" value={leadsPerPage} onChange={this.changeLeadCount}
										options={[
											{ "name": "25", "value": "25" },
											{ "name": "50", "value": "50" },
											{ "name": "100", "value": "100" },
											{ "name": "200", "value": "200" },
											{ "name": "All Results", "value": "all" }
										]}
									/>
								</div> */}

								<SearchForm page="leads" />
								<div className='table_wrapper_inner'>
									<div className="table-heading">
										<div className="sortable" onClick={() => this.sortBy("createdTime")}>Date  <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("name")}>Name  <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("email")}>Email  <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("phone")}>Phone  <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("conversionStatus")}>Lead Status <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("channel")}>Channel  <span><i className="fas fa-sort"></i></span></div>
										<div className="sortable" onClick={() => this.sortBy("form")}>Form  <span><i className="fas fa-sort"></i></span></div>
										<div>Preferred Store</div>
										<div>Product of Interest</div>
										<div>Message</div>
										<div>Lead Value</div>
									</div>
									<ul className="table-body">
										{renderLeads}
									</ul>
								</div>
								<div className="pagination">
									<ul className="page-numbers">
										{renderPageNumbers}
									</ul>
									{/* <InputRow 
										label="Results Per Page" type="select" property="leadsPerPage" id="resultsPerPage" value={leadsPerPage} onChange={this.changeLeadCount}
										options={[
											{ "name": "25", "value": "25" },
											{ "name": "50", "value": "50" },
											{ "name": "100", "value": "100" },
											{ "name": "200", "value": "200" },
											{ "name": "All Results", "value": "all" }
										]}
									/> */}
								</div>
							
						</section>
					</React.Fragment>
				</div>
			)
		)

	}
}

export default LeadTracking;